













































































































































































































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";
//import Sprint from "@/components/board/sprint/Sprint.vue";
const Sprint = () => import("@/components/board/sprint/Sprint.vue");
import {Objective as ObjectiveObject, ObjectivePaginator,} from "@/typescript/types";
import ObjectiveCategoryService from "@/ObjectiveCategoryService";
import sortBy from "lodash/sortBy";
import Template from "@/components/workspace/Template.vue";
//import RealTimeClock from "@/components/general/RealTimeClock.vue";
const RealTimeClock = () => import("@/components/general/RealTimeClock.vue");
import {
  addDays, addMonths, addQuarters, addWeeks, addYears, endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear, startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear, subDays, subMonths, subQuarters, subWeeks, subYears
} from "date-fns";
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import {ObjectivesInDateRangeQuery} from "@/graphql/objective";




@Component({
  components: {RealTimeClock, Template, Sprint},
  apollo:{
    getObjectivesInDateRange: {
      query: ObjectivesInDateRangeQuery,
      fetchPolicy: 'network-only',
      skip() {
        return !this.board
      },
      variables() {
        return {
          board_id: this.board.id,
          startDate: this.selectedStartDate,
          endDate: this.selectedEndDate,
        }
      }
    }
  }
})
export default class Schedule extends Vue {
  expandSchedule: boolean = false;
  currentlyDragging: boolean = false;
  polling: any | null = null;
  pollingWithoutTrigger: any | null = null;
  isLoading: boolean = false;
  isDateRangeSelected: boolean = false;
  dateRangeName: string | null = null;
  dateRange: Array<any> = [
    'Daily View',
    'Weekly View',
    'Monthly View',
    'Quarterly View',
    'Yearly View',
    'Custom Date Select',
    'Remove Date Selection'
  ]

  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  previousStartDate: Date | null = null;
  previousEndDate: Date | null = null;
  nextStartDate: Date | null = null;
  nextEndDate: Date | null = null;

  selectedDateRange: string | null = null;
  getObjectivesInDateRange: ObjectivePaginator | null = null;

  hasMorePagesOngoing: boolean = false;
  hasMorePagesUpcomming: boolean = false;
  hasMorePagesDue: boolean = false;

  @Prop({required: true})
  objectives!: ObjectiveObject[];

  @Prop({default: true, required: false})
  isMember!: boolean;

  @Prop({default: false, required: false})
  isWorkspaceLevel!: boolean


  page: number = 1;
  newCount = 0;

  allObjectives: ObjectiveObject[] = [];

  get board() {
    return this.$store.state.board;
  }

  get dateRangeObjectives(){
    if(this.getObjectivesInDateRange){
      return this.getObjectivesInDateRange.data;
    }
    return [];

  }

  get hasMorePages() {
    if (this.getObjectivesInDateRange) {
      return this.getObjectivesInDateRange.paginatorInfo.hasMorePages;
    }
    return false;
  }

  get due() {
    let objectives = ObjectiveCategoryService.getDue(this.allObjectives);
    return sortBy(objectives, ['start_date']);
  }

  get ongoing() {

    let objectives = ObjectiveCategoryService.getOngoing(this.allObjectives);

    return sortBy(objectives, ['start_date']);
  }

  get upcomming() {

    let filterObjectives = ObjectiveCategoryService.getUpcomming(this.allObjectives);
    return sortBy(filterObjectives, ['start_date']);
  }

  // get completed() {
  //   return sortBy(ObjectiveCategoryService.getCompleted(this.allObjectives),['start_date']);
  // }


  get navigationButtonText() {
    if(this.$route.name === 'board-layout') {
      return 'More';
    } else if(this.$route.name === 'board-settings') {
      return 'Goals'
    } else {
      return 'Back';
    }
  }


  toggleSecondaryTab() {
    if(this.$route.name === 'board-settings') {
      this.$router.push({name: 'board-goals' });
    } else {
      this.$router.push({name: 'board-settings' });
    }
  }

  drag(data: any): void {
    if(data.addedIndex && data.containerName == 'ongoing') {
      data.payload['start_date'] = new Date();
    }
    this.$emit('drag', data);
  }

  scrollIntoView(name: string)  {
    this.expandSchedule = true;
    this.$nextTick(function () {
      window.location.href = '#' + name;
    });
  }

  objectiveCreated(objective: ObjectiveObject): void {
    this.$emit('objective-created', objective)
  }

  setObjective() {
    this.allObjectives = [];
    this.allObjectives = this.$props.objectives
  }

  beforeDestroy () {
    clearInterval(this.polling)
  }

  loadAllObjectives() {
    this.expandSchedule = !this.expandSchedule;
    if(this.expandSchedule) {
      this.$events.fire('load-all-objectives');
      this.isLoading = true;
      clearInterval(this.pollingWithoutTrigger)
    }
  }

  createDateRange(selectedRange: string) {

    this.selectedDateRange = selectedRange;
    this.dateRangeName = selectedRange;
    this.page = 1;
    let d = new Date();
    this.isLoading = true;


    this.$forceUpdate();
    switch (selectedRange) {
      case 'Daily View':
        this.isDateRangeSelected = true;
        this.selectedStartDate = startOfDay(d);
        this.selectedEndDate = endOfDay(d);
        this.$apollo.queries.getObjectivesInDateRange.refetch()

        this.previousStartDate = subDays(this.selectedStartDate, 1)
        this.previousEndDate = subDays(this.selectedEndDate, 1)
        this.nextStartDate = addDays(this.selectedStartDate, 1)
        this.nextEndDate = endOfDay(this.nextStartDate)

        //this.$events.fire('custom-date-range', {'startDate': this.selectedStartDate, 'endDate': this.selectedEndDate})

        break;
      case 'Weekly View':
        this.isDateRangeSelected = true;
        this.selectedStartDate = startOfWeek(d)
        this.selectedEndDate = endOfWeek(d)
        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subWeeks(this.selectedStartDate, 1)
        this.previousEndDate = endOfWeek(this.previousStartDate)
        this.nextStartDate = addWeeks(this.selectedStartDate, 1)
        this.nextEndDate = endOfWeek(this.nextStartDate)



        break;
      case 'Monthly View':
        this.isDateRangeSelected = true;
        this.selectedStartDate = startOfMonth(d)
        this.selectedEndDate = endOfMonth(d)
        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subMonths(this.selectedStartDate, 1)
        this.previousEndDate = endOfMonth(this.previousStartDate)
        this.nextStartDate = addMonths(this.selectedStartDate, 1)
        this.nextEndDate = endOfMonth(this.nextStartDate)


        break;
      case 'Quarterly View':
        this.isDateRangeSelected = true;
        this.selectedStartDate = startOfQuarter(new Date(d.setHours(0,0,0)))
        this.selectedEndDate = endOfQuarter(new Date(d.setHours(23, 59, 59)))
        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subQuarters(this.selectedStartDate, 1)
        this.previousEndDate = endOfQuarter(this.previousStartDate)
        this.nextStartDate = addQuarters(this.selectedStartDate, 1)
        this.nextEndDate = endOfQuarter(this.nextStartDate)


        break;
      case 'Yearly View':
        this.isDateRangeSelected = true;
        this.selectedStartDate = startOfYear(new Date(d.setHours(0,0,0)))
        this.selectedEndDate = endOfYear(new Date(d.setHours(23, 59, 59)))
        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subYears(this.selectedStartDate, 1)
        this.previousEndDate = endOfYear(this.previousStartDate)
        this.nextStartDate = addYears(this.selectedStartDate, 1)
        this.nextEndDate = endOfYear(this.nextStartDate)


        break;
      case 'Custom Date Select':
        this.previousStartDate = null;
        this.nextEndDate = null;
        this.isDateRangeSelected = true;
        this.$buefy.modal.open({
          component: SmartDateTime,
          width: '280px',
          // @ts-ignore
          customClass: 'is-paddingless top-right',
          parent: this,
          props: {'remove-due-date': true, 'set-date': new Date(), 'range': true},
          events: {
            //@ts-ignore ToDo: fix type issue
            'dates': values => {
              if (values) {
                this.selectedStartDate = new Date(values.date1.setHours(0, 0, 0))
                this.selectedEndDate = new Date(values.date2.setHours(23, 59, 59))

                this.dateRangeName = this.$options.filters?.moment(this.selectedStartDate, 'MMM d')+' - '+this.$options.filters?.moment(this.selectedEndDate, 'MMM d');
                this.$apollo.queries.getObjectivesInDateRange.refetch()

              }

            }
          }
        })

        break;
      case 'Remove Date Selection':
        this.isDateRangeSelected = false;
        this.selectedDateRange = null;
        this.page = 1;
        this.selectedStartDate = new Date();
        this.selectedEndDate = new Date();
        this.dateRangeName = null;
        this.$events.fire('load-ongoing-objectives');
        break;
    }

  }

  goToPrevious() {
    //@ts-ignore
    this.selectedStartDate = this.previousStartDate;
    //@ts-ignore
    this.selectedEndDate = this.previousEndDate;

    switch (this.dateRangeName) {
      case 'Daily View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()

        this.previousStartDate = subDays(this.selectedStartDate, 1)
        this.previousEndDate = endOfDay(this.previousStartDate)

        this.nextStartDate = addDays(this.selectedStartDate, 1)
        this.nextEndDate = endOfDay(this.nextStartDate)




        break;
      case 'Weekly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subWeeks(this.selectedStartDate, 1)
        this.previousEndDate = endOfWeek(this.previousStartDate)
        this.nextStartDate = addWeeks(this.selectedStartDate, 1)
        this.nextEndDate = endOfWeek(this.nextStartDate)


        break;
      case 'Monthly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subMonths(this.selectedStartDate, 1)
        this.previousEndDate = endOfMonth(this.previousStartDate)

        this.nextStartDate = addMonths(this.selectedStartDate, 1)
        this.nextEndDate = endOfMonth(this.nextStartDate)



        break;
      case 'Quarterly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subQuarters(this.selectedStartDate, 1)
        this.previousEndDate = endOfQuarter(this.previousStartDate)
        this.nextStartDate = addQuarters(this.selectedStartDate, 1)
        this.nextEndDate = endOfQuarter(this.nextStartDate)



        break;
      case 'Yearly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subYears(this.selectedStartDate, 1)
        this.previousEndDate = endOfYear(this.previousStartDate)
        this.nextStartDate = addYears(this.selectedStartDate, 1)
        this.nextEndDate = endOfYear(this.nextStartDate)



        break;

    }
  }

  goToNext() {
    //@ts-ignore
    this.selectedStartDate = this.nextStartDate;
    //@ts-ignore
    this.selectedEndDate = this.nextEndDate;

    switch (this.dateRangeName) {
      case 'Daily View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()

        this.previousStartDate = subDays(this.selectedStartDate, 1)
        this.previousEndDate = endOfDay(this.previousStartDate)

        this.nextStartDate = addDays(this.selectedStartDate, 1)
        this.nextEndDate = endOfDay(this.nextStartDate)




        break;
      case 'Weekly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subWeeks(this.selectedStartDate, 1)
        this.previousEndDate = endOfWeek(this.previousStartDate)
        this.nextStartDate = addWeeks(this.selectedStartDate, 1)
        this.nextEndDate = endOfWeek(this.nextStartDate)

        break;
      case 'Monthly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subMonths(this.selectedStartDate, 1)
        this.previousEndDate = endOfMonth(this.previousStartDate)

        this.nextStartDate = addMonths(this.selectedStartDate, 1)
        this.nextEndDate = endOfMonth(this.nextStartDate)



        break;
      case 'Quarterly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subQuarters(this.selectedStartDate, 1)
        this.previousEndDate = endOfQuarter(this.previousStartDate)
        this.nextStartDate = addQuarters(this.selectedStartDate, 1)
        this.nextEndDate = endOfQuarter(this.nextStartDate)



        break;
      case 'Yearly View':

        this.$apollo.queries.getObjectivesInDateRange.refetch()
        this.previousStartDate = subYears(this.selectedStartDate, 1)
        this.previousEndDate = endOfYear(this.previousStartDate)
        this.nextStartDate = addYears(this.selectedStartDate, 1)
        this.nextEndDate = endOfYear(this.nextStartDate)



        break;

    }
  }

  openDateRangePicker() {

    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless top-right',
      parent: this,
      props: {'remove-due-date': true, 'set-date': new Date(), 'range': true},
      events: {
        //@ts-ignore ToDo: fix type issue
        'dates': values => {
          if (values) {
            return [values[0],values[1]];
          }

        }
      },
    });


  }


  changePage() {
    this.page++;

    if(this.dateRangeObjectives.length > 0) {
      this.dateRangeObjectives!.splice(this.dateRangeObjectives.length - this.newCount, this.newCount);
    }

    this.newCount = 0;

    this.$apollo.queries.getObjectivesInDateRange.fetchMore({
      variables: {
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
        page: this.page,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
        //previousResult = {'getObjectivesInDateRange':this.getObjectivesInDateRange}
        return {
          getObjectivesInDateRange: {
            __typename: previousResult.getObjectivesInDateRange.__typename,
            /**
             * Merging the tag list
             */

            data: [...previousResult.getObjectivesInDateRange.data, ...fetchMoreResult.getObjectivesInDateRange.data],
            paginatorInfo: fetchMoreResult.getObjectivesInDateRange.paginatorInfo,
          },
        };
      },
    });


  }

  openAllActivities() {
    this.$events.fire('open-all-activities-workspace', !this.expandSchedule)
  }

  mounted() {
    this.polling = setInterval(() => {
      this.setObjective();
    }, 1000);

    this.pollingWithoutTrigger = setInterval(() => {
      this.$events.fire('load-ongoing-objectives')
    }, 3600000);

    this.$events.listen('all-objectives-loaded', () => {
      this.isLoading = false;
    })

    this.$events.listen('objective-created', (_eventData) => {
      if(this.isDateRangeSelected) {
        this.$apollo.queries.getObjectivesInDateRange.refetch()
      }

    })

    this.$events.listen('objective-updated', (_eventData) => {
      if(this.isDateRangeSelected) {
        this.$apollo.queries.getObjectivesInDateRange.refetch()
      }
    })

    this.$events.listen('dragged-in-date-range', (_) => {
      this.$apollo.queries.getObjectivesInDateRange.refetch()
    })

    this.$events.listen('has-more-pages-in-objective-ongoing', (eventData: any) => {

      this.hasMorePagesOngoing = eventData.hasMorePages

    })

    this.$events.listen('has-more-pages-in-objective-upcomming', (eventData: any) => {

      this.hasMorePagesUpcomming = eventData.hasMorePages;
    })

    this.$events.listen('has-more-pages-in-objective-due', (eventData: any) => {
      this.hasMorePagesDue = eventData.hasMorePages
    })

  }

  loadMore(cat: String) {
    this.$events.fire('load-more-objectives', {category: cat, workspaceLevel: this.isWorkspaceLevel  });
  }
}
