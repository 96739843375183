























































import {Component, Mixins} from "vue-property-decorator";
import {ObjectiveNotificationMixin} from "@/mixins/ObjectiveNotificationMixin";
import MasterLayout from "@/components/layout/MasterLayout.vue";
import Objective from "@/components/board/objective/Objective.vue";
import InfiniteLoading from 'vue-infinite-loading';
import {CompletedObjectiveQuery, CompletedObjectiveReactedSubscription} from "@/graphql/workspace";
import {ObjectivePaginator, Reaction} from "@/typescript/types";
import WorkspaceObjectiveItem from "@/components/workspace/WorkspaceObjectiveItem.vue";

@Component({
  components: {WorkspaceObjectiveItem, MasterLayout, Objective, InfiniteLoading},
  apollo: {
    getCompletedObjectives: {
      fetchPolicy: 'network-only',
      query: CompletedObjectiveQuery,
      subscribeToMore:{
        document: CompletedObjectiveReactedSubscription,
        variables(){
          return {
            workspace_id: this.$store.state.workspace.id,
            board_id: null
          };
        },
        updateQuery(_previous, {subscriptionData}) {

          this.newCount++;

          let previousMessages = _previous.getCompletedObjectives.data;
          let newMessage = subscriptionData.data.completedObjectiveReacted;

          let duplicate = previousMessages.findIndex((obj: any) => {
            return obj.id === newMessage.id
          })

          if (duplicate != -1) {
            previousMessages = previousMessages.filter((obj: any) => {
              return obj.id !== duplicate.id
            })
          }

          return {
            getCompletedObjectives: {
              data: previousMessages,
              paginatorInfo: _previous.getCompletedObjectives.paginatorInfo,
              __typename: _previous.getCompletedObjectives.__typename,
            },
          };
        }
      },
      variables() {
        return {
          id: this.$store.state.workspace.id,
        };
      },
      result() {
        //
      },


    },
  },
})
export default class WorkspaceAllObjectivesCompleted extends Mixins(ObjectiveNotificationMixin) {
  pageName: string = 'All completed objectives';
  page = 1;
  newCount = 0;
  infiniteStateUp: any | null = null;
  getCompletedObjectives: ObjectivePaginator | null = null;
  filesLoading: boolean = false;
  showCompletedActive: boolean = false;

  get completedObjectives(){
    if(this.getCompletedObjectives){
      return [...this.getCompletedObjectives.data.slice().reverse()];
    }
    return [];

  }

  get workspaceName() {
    return this.$store.state.workspace.name;
  }

  get hasMorePages() {
    if (this.getCompletedObjectives) {
      return this.getCompletedObjectives.paginatorInfo.hasMorePages;
    }
    return false;
  }

  changePage() {
    this.filesLoading = true;
    this.page++;
    this.completedObjectives!.splice(this.completedObjectives.length - this.newCount, this.newCount);
    this.newCount = 0;


    setTimeout(() => {

      this.infiniteStateUp.loaded();
    }, 5000);

    this.$apollo.queries.getCompletedObjectives.fetchMore({
      variables: {
        page: this.page,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
        this.filesLoading = false;
        return {
          getCompletedObjectives: {
            __typename: previousResult.getCompletedObjectives.__typename,
            /**
             * Merging the tag list
             */
            data: [...previousResult.getCompletedObjectives.data, ...fetchMoreResult.getCompletedObjectives.data],
            paginatorInfo: fetchMoreResult.getCompletedObjectives.paginatorInfo,
          },
        };
      },
    });

  }

  infiniteHandlerUp($state: any){
    this.infiniteStateUp = $state;
    this.changePage();
  }

  statusUpdated(value: any){
    let index = this.completedObjectives?.findIndex((obj: any) => obj.id === value.objId);
    //@ts-ignore
    this.completedObjectives[index].status = value.status;
  }

  newReaction(reaction: Reaction, id: string) {
    let objective = this.completedObjectives!.find((obj: any) => {
      return obj.id === id;
    });
    objective?.reactions?.push(reaction);
  }


}
