




































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import {
  UpdateObjective,
} from "@/graphql/objective";
import {Container, Draggable} from "vue-smooth-dnd";
import Objective from "@/components/board/objective/Objective.vue";
import {Objective as ObjectiveObject} from "@/typescript/types";
// import ObjectiveFilterService from "@/ObjectiveFilterService";
// import ObjectiveCategoryService from "@/ObjectiveCategoryService";
// import orderBy from 'lodash/orderBy';
import {ObjectiveNotificationMixin} from "@/mixins/ObjectiveNotificationMixin";
//import {format} from "date-fns";
import UserStatus from "@/components/user/UserStatus.vue";
import WorkspaceMemberItem from "@/components/navigation/WorkspaceMemberItem.vue";
import WorkspaceObjectiveItem from "@/components/workspace/WorkspaceObjectiveItem.vue";
import WorkspaceAllObjectivesCompleted from "@/components/workspace/WorkspaceAllObjectivesCompleted.vue";
import Schedule from "@/components/objectives/Schedule.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";
import ObjectiveLists from "@/components/objectives/ObjectiveLists.vue";



@Component({
  components: {
    WorkspacePage,
    UserAvatar,
    WorkspaceAllObjectivesCompleted, WorkspaceMemberItem, Objective, Container, Draggable, WorkspaceObjectiveItem, Schedule, ObjectiveLists},
})
export default class WorkspaceAllObjectives extends Mixins(ObjectiveNotificationMixin) {
  showGoals: boolean = true;
  weekExpanded: boolean = true;
  scheduledExpanded: boolean = false;
  laterExpanded: boolean = true;
  isLoading: boolean = true

  expand: boolean = false;

  totalOngoing: Number | null = null;
  totalUpcomming: Number | null = null;
  totalDue: Number | null = null;

  @Prop({ required: false, default: true })
  showLater!: boolean;

  get workspace() {
    return this.$store.state.workspace;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get userFilterId() {
    return this.$route.params.user_id;
  }
  get pageName() {
    if(this.$route.params.member_data) {
      return this.$route.params.member_data;
    } else {
      return 'Schedule'
    }
  }

  get dailyLoggedInStatus(){
    return this.$store.state.dailyLoggedInStatus;
  }

  /*
  isMe(userId: string) {
    if (userId === this.meId) {
      return true;
    } else {
      return false;
    }
  }
  */

  showUserUpdateModal(date: string){
    this.$store.commit('set_dailyLoggedInStatus', date);
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }

  destroyed() {
    document.title = "Pembio";
  }

  drag(data: any): void {
    if ('containerName' in data && data.addedIndex != null) {
      if (data.containerName === "ongoing") {
        data.payload['start_date'] = new Date();
        this.updateObjective(data.payload);
      }

      if (data.containerName === "upcomming") {
        data.payload.start_date = null;
        this.updateObjective(data.payload);
      }
    }
  }


  updateObjective(item: ObjectiveObject) {


    return this.$apollo.mutate({
      mutation: UpdateObjective,
      variables: {
        id: item.id,
        start_date: item.start_date,
        due_date: item.due_date
      },
    }).then((response) => {
      return response.data.updateObjective;
    }).catch((_error) => {

    });
  }


  mounted(){
    document.title = "Pembio | Schedule";
    if(this.showLater) {
      this.laterExpanded = this.showLater;
    }


    // let today = new Date().getHours();
    // if(today > 6 && this.$store.state.workspace.members.length > 1){
    //   if(this.dailyLoggedInStatus === null){
    //     this.showUserUpdateModal(format(new Date(), 'dd/MM/yyyy'));
    //   }else{
    //     let date = format(new Date(), 'dd/MM/yyyy');
    //     if(this.dailyLoggedInStatus < date){
    //       this.showUserUpdateModal(date);
    //     }
    //   }
    // }





    this.$events.listen('total-count-ongoing', (total: any) => {
      this.totalOngoing = total;
    })

    this.$events.listen('total-count-upcomming', (total: any) => {
      this.totalUpcomming = total;
    })

    this.$events.listen('total-count-due', (total: any) => {
      this.totalDue = total;
    })
  }

}
