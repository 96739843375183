
























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Objective as ObjectiveObject, Objective as ObjectiveType} from "@/typescript/types";
import PusherService from "@/PusherService";
import ObjectiveDetailed from "@/components/board/objective/ObjectiveDetailed.vue";
import isPast from 'date-fns/isPast';


@Component({
  components: {},
})
export default class WorkspaceObjectiveItem extends Vue {

  @Prop({default: undefined, required: true})
  objective!: ObjectiveType;

  @Prop({default: undefined, required: false})
  index!: Number;

  @Prop({default: false, required: false})
  containerName!: String;

  get isPast() {
    if(isPast(new Date(this.objective.due_date))){
      return true;
    }
    return false;
  }

  openObjectiveDetailed() {
    PusherService.objectiveSubscription(this.objective.pid);

    let modal = this.$buefy.modal.open({
      component: ObjectiveDetailed,
      props: {
        objectivePid: this.objective.pid
      },
      events: {
        'objective-updated': (objective: ObjectiveObject) => {
          this.$emit('objective-updated', objective)
        }
      },
      // @ts-ignore
      customClass: 'objective-modal',
      parent: this,
      animation: 'none',
    });

    modal.$on("close", () => PusherService.unsubscribeObjectiveSubscription(this.objective.pid));
  }
}
