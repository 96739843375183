import {Objective as ObjectiveObject} from "@/typescript/types";
import {isFuture, isPast, isThisWeek, isToday} from "date-fns";

class ObjectiveCategoryService{
  getToday(objectiveItems: ObjectiveObject[]): ObjectiveObject[]{
    const objectives = [];
    for (let i = 0; i < objectiveItems.length; i++) {
      if (isToday(new Date(objectiveItems[i].due_date))) {
        objectives.push(
          objectiveItems[i]
        );
      }
    }
    return objectives;
  }

  getWeeks(objectiveItems: ObjectiveObject[]): ObjectiveObject[]{
    const objectives = [];
    for (let i = 0; i < objectiveItems.length; i++) {
      if (isThisWeek(new Date(objectiveItems[i].due_date)) && !isToday(new Date(objectiveItems[i].due_date))) {
        objectives.push(
          objectiveItems[i]
        );
      }
    }
    return objectives;
  }

  getScheduled(objectiveItems: ObjectiveObject[]): ObjectiveObject[]{
    const concatObjectives = this.getWeeks(objectiveItems).concat(this.getToday(objectiveItems));
    let filterObjectives = objectiveItems.filter((obj: any) => {
      return !concatObjectives.find(element => {
        return element.id === obj.id;
      });
    })
    filterObjectives = filterObjectives.filter((obj: any) => {
      return obj.due_date && obj.status != 'completed';
    });
    return filterObjectives;
  }

  getUnscheduled(objectiveItems: ObjectiveObject[]): ObjectiveObject[]{
    return objectiveItems.filter((obj: any) => {
      return !obj.due_date;
    })
  }

  getDue(objectiveItems: ObjectiveObject[]): ObjectiveObject[] {
    const objectives = [];
    for (let i = 0; i < objectiveItems.length; i++) {
      if (objectiveItems[i].due_date != null && isPast(new Date(objectiveItems[i].due_date)) && objectiveItems[i].status != 'completed') {
        objectives.push(
          objectiveItems[i]
        );
      }
    }
    return objectives;
  }

  getOngoing(objectiveItems: ObjectiveObject[]): ObjectiveObject[] {
    const objectives = [];
    for (let i = 0; i < objectiveItems.length; i++) {
      if (objectiveItems[i].status != 'completed' && objectiveItems[i].start_date != null && isPast(new Date(objectiveItems[i].start_date)) && (objectiveItems[i].due_date == null || isFuture(new Date(objectiveItems[i].due_date))) ){
        objectives.push(
          objectiveItems[i]
        );
      }
    }
    return objectives;
  }

  getUpcomming(objectiveItems: ObjectiveObject[]): ObjectiveObject[] {
    const objectives = [];
    for (let i = 0; i < objectiveItems.length; i++) {
      if (objectiveItems[i].status != 'completed' && (objectiveItems[i].start_date == null || isFuture(new Date(objectiveItems[i].start_date))) && (objectiveItems[i].due_date == null || isFuture(new Date(objectiveItems[i].due_date))) ){
        objectives.push(
          objectiveItems[i]
        );
      }
    }
    return objectives;
  }

  getCompleted(objectiveItems: ObjectiveObject[]): ObjectiveObject[] {
    const objectives = [];
    for (let i = 0; i < objectiveItems.length; i++) {
      if (objectiveItems[i].status === 'completed'){
        objectives.push(
          objectiveItems[i]
        );
      }
    }
    return objectives;
  }
}

export default new ObjectiveCategoryService;